<template>
  <div
    class="font-gbold text-white text-center pb-2 cursor-pointer py-2 border-white border-b-2 mb-9 w-28 m-auto"
  >
    Load More +
  </div>
</template>

<script>
export default {};
</script>

<style></style>

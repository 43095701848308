<template>
  <div
    class="animate-floating z-10 cursor-pointer flex justify-center items-center w-12 h-12 absolute top-24 bg-myRed right-4 text-white rounded-full py-1 sm:w-32 sm:rounded-md sm:right-8 md:top-26 md:right-10 lg:right-16 enlarge"
  >
    <span class="hidden sm:block font-gbold ">Add Post</span>
    <span class="text-3xl font-glight sm:ml-2">+</span>
  </div>
</template>

<script>
export default {};
</script>

<style></style>

<template>
  <div
    class="fixed top-0 bottom-0 left-0 right-0 z-10 grid place-items-center bg-black opacity-80 "
  >
    <div class="text-white animate-pulse font-gbold text-xl">
      Loading ...
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>

<template>
  <!-- Like Light -->
  <svg
    v-if="name === 'likeLight'"
    class="cursor-pointer text-myBlue"
    fill="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m1.75 24h2.5c.965 0 1.75-.785 1.75-1.75v-.366c1.512.77 4.946 2.116 10.531 2.116h1.6c2.318 0 4.286-1.658 4.679-3.942l1.12-6.5c.238-1.387-.145-2.797-1.049-3.871-.903-1.072-2.227-1.687-3.63-1.687h-4.536c.283-.76.566-1.868.566-3.25 0-3.757-2.126-4.75-3.25-4.75-2.076 0-2.25 1.936-2.25 3.75 0 2.214-2.483 4.054-3.794 4.872-.067-.904-.816-1.622-1.737-1.622h-2.5c-.965 0-1.75.785-1.75 1.75v13.5c0 .965.785 1.75 1.75 1.75zm9.531-20.25c0-2.25.381-2.25.75-2.25.704 0 1.75.866 1.75 3.25 0 2.216-.868 3.574-.874 3.584-.153.23-.168.526-.037.77.13.244.385.396.661.396h5.72c.96 0 1.865.42 2.483 1.153.619.734.881 1.7.718 2.649l-1.12 6.5c-.269 1.562-1.615 2.697-3.201 2.697h-1.6c-6.803.001-10.211-2.127-10.531-2.338v-9.81c.833-.442 5.281-2.972 5.281-6.601zm-9.781 5c0-.138.112-.25.25-.25h2.5c.138 0 .25.112.25.25v13.5c0 .136-.114.25-.25.25h-2.5c-.138 0-.25-.112-.25-.25z"
    />
  </svg>
  <!-- Like Dark -->
  <svg
    class="cursor-pointer text-myBlue"
    v-else-if="name === 'likeDark'"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m1.75 23h2.5c.965 0 1.75-.785 1.75-1.75v-11.5c0-.965-.785-1.75-1.75-1.75h-2.5c-.965 0-1.75.785-1.75 1.75v11.5c0 .965.785 1.75 1.75 1.75z"
    />
    <path
      d="m12.781.75c-1 0-1.5.5-1.5 3 0 2.376-2.301 4.288-3.781 5.273v12.388c1.601.741 4.806 1.839 9.781 1.839h1.6c1.95 0 3.61-1.4 3.94-3.32l1.12-6.5c.42-2.45-1.46-4.68-3.94-4.68h-4.72s.75-1.5.75-4c0-3-2.25-4-3.25-4z"
    />
  </svg>
  <!-- Comment -->
  <svg
    v-else-if="name === 'comment'"
    class="cursor-pointer text-myBlue"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    enable-background="new 0 0 511.072 511.072"
    viewBox="0 0 511.072 511.072"
  >
    <g id="Speech_Bubble_48_">
      <g>
        <path
          d="m74.39 480.536h-36.213l25.607-25.607c13.807-13.807 22.429-31.765 24.747-51.246-36.029-23.644-62.375-54.751-76.478-90.425-14.093-35.647-15.864-74.888-5.121-113.482 12.89-46.309 43.123-88.518 85.128-118.853 45.646-32.963 102.47-50.387 164.33-50.387 77.927 0 143.611 22.389 189.948 64.745 41.744 38.159 64.734 89.63 64.734 144.933 0 26.868-5.471 53.011-16.26 77.703-11.165 25.551-27.514 48.302-48.593 67.619-46.399 42.523-112.042 65-189.83 65-28.877 0-59.01-3.855-85.913-10.929-25.465 26.123-59.972 40.929-96.086 40.929zm182-420c-124.039 0-200.15 73.973-220.557 147.285-19.284 69.28 9.143 134.743 76.043 175.115l7.475 4.511-.23 8.727c-.456 17.274-4.574 33.912-11.945 48.952 17.949-6.073 34.236-17.083 46.99-32.151l6.342-7.493 9.405 2.813c26.393 7.894 57.104 12.241 86.477 12.241 154.372 0 224.682-93.473 224.682-180.322 0-46.776-19.524-90.384-54.976-122.79-40.713-37.216-99.397-56.888-169.706-56.888z"
        />
      </g>
    </g>
  </svg>
  <!-- Plus -->
  <svg
    v-else-if="name === 'plus'"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0"
    />
    <path
      d="m368 272h-224c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h224c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
    />
    <path
      d="m256 384c-8.832031 0-16-7.167969-16-16v-224c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v224c0 8.832031-7.167969 16-16 16zm0 0"
    />
  </svg>
  <!-- Arrows -->
  <svg
    v-if="name === 'rightArrow' || name === 'leftArrow'"
    xmlns="http://www.w3.org/2000/svg"
    class="w-7 p-1 cursor-pointer bg-myRed text-white rounded-full"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      :d="[name === 'rightArrow' ? 'M9 5l7 7-7 7' : 'M15 19l-7-7 7-7']"
    />
  </svg>
  <!-- Close -->
  <svg
    v-else-if="name === 'close'"
    xmlns="http://www.w3.org/2000/svg"
    class="h-4 w-4 cursor-pointer"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
  <!-- Send Comment rocket -->
  <svg
    v-else-if="name === 'sendComment'"
    style="fill:white"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m8.75 17.612v4.638c0 .324.208.611.516.713.077.025.156.037.234.037.234 0 .46-.11.604-.306l2.713-3.692z"
    />
    <path
      d="m23.685.139c-.23-.163-.532-.185-.782-.054l-22.5 11.75c-.266.139-.423.423-.401.722.023.3.222.556.505.653l6.255 2.138 13.321-11.39-10.308 12.419 10.483 3.583c.078.026.16.04.242.04.136 0 .271-.037.39-.109.19-.116.319-.311.352-.53l2.75-18.5c.041-.28-.077-.558-.307-.722z"
    />
  </svg>
  <!-- Info icon -->
  <svg
    v-else-if="name === 'info'"
    class="cursor-pointer"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    viewBox="0 0 16 16"
  >
    <path
      d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
    />
    <path
      d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
    />
  </svg>
</template>
<script>
export default {
  name: "posts-svg",
  props: ["name"],
};
</script>
<style scoped>
svg {
  width: 20px;
  margin: 0 10px;
}
</style>
